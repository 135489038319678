<template>
    <div id="task-common-page" class="task-page sn_table_group">
        <sn-search
            class="sn-search search-icon clearfix"
            :snSearch.sync="snSearch"
            @handleChange="handleChange"
        ></sn-search>
        <div class="content">
            <slot></slot>
            <div v-if="showTree" class="leftTree">
                <p class="title">{{ title }}</p>
                <div class="tree scrollbar">
                    <el-tree
                        class="task-tree"
                        :data="list"
                        :props="defaultProps"
                        :expand-on-click-node="false"
                        :render-content="renderContent"
                        @node-click="changeCurrent"
                    ></el-tree>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "daily-patrol",
    props: {
        legendList: {
            default() {
                return [];
            },
        },
        title: {
            default() {
                return "";
            },
        },
        snSearch: {
            default() {
                return {};
            },
        },
        list: {
            default() {
                return [];
            },
        },
        currentTree: {
            default() {
                return "";
            },
        },
        currentId: {
            default() {
                return "";
            },
        },
        listConfig: {
            default() {
                return {
                    name: "label",
                    value: "value",
                };
            },
        },
        showTree: {
            default() {
                return true;
            },
        },
        taskMap: {
            default() {
                return null;
            },
        },
        defaultProps: {
            default() {
                return {
                    children: "child",
                    label: "label",
                };
            },
        },
    },
    data() {
        return {};
    },
    watch: {},
    created() {
        this.defaultProps.label = this.listConfig.name;
        this.defaultProps.children = this.listConfig.children;
    },
    mounted() {
        this.autoResize();
        $(window).resize(this.autoResize);
    },
    methods: {
        handleChange(type, value) {
            this.$emit("handleChange", type, value);
        },
        changeCurrent(data, node) {
            let multiArr = [
                "ontologyDisease",
                "heritageMonomer",
                "dailyPatrol",
            ];
            if (data.geojson) {
                let center1 = window.turf.centerOfMass(
                    JSON.parse(data.geojson)
                );
                let lat = center1.geometry.coordinates[1];
                let lng = center1.geometry.coordinates[0];
                this.taskMap.flyTo([lat, lng], 15);
            }
            if (this.$route.name === "ancientTeaTree") {
                // 古茶树，第一级是管护区，没有点击事件
                console.log(555555, data, node);
                if (node.level === 1) return;
                let value = data[this.listConfig.value];
                let label = data[this.listConfig.name];
                let index = -1;
                let index2 = -1;
                let index3 = -1;
                this.list.forEach((item, i) => {
                    if (item[this.listConfig.value] === value) {
                        index = i;
                    } else if (item.CHILDREN && item.CHILDREN.length >= 0) {
                        item.CHILDREN.forEach((item2, j) => {
                            if (item2[this.listConfig.value] === value) {
                                index2 = j;
                                index = i;
                            } else if (
                                item2.CHILDREN &&
                                item2.CHILDREN.length >= 0
                            ) {
                                item2.CHILDREN.forEach((item3, k) => {
                                    if (
                                        item3[this.listConfig.value] === value
                                    ) {
                                        index3 = k;
                                        index2 = j;
                                        index = i;
                                    }
                                });
                            }
                        });
                    }
                });
                this.$emit("handleChange", "list", [
                    value,
                    label,
                    index,
                    data.JCSJCOUNT,
                    data,
                    index2,
                    index3,
                ]);
            } else if (multiArr.indexOf(this.$route.name) !== -1) {
                // if (node.level === 0) return;
                if (node.isLeaf === false) return;
                let value = data[this.listConfig.value];
                let label = data[this.listConfig.name];
                let index = -1;
                let index2 = -1;
                let index3 = -1;
                this.list.forEach((item, i) => {
                    if (item[this.listConfig.value] === value) {
                        index = i;
                    } else if (item.CHILDREN && item.CHILDREN.length >= 0) {
                        item.CHILDREN.forEach((item2, j) => {
                            if (item2[this.listConfig.value] === value) {
                                index2 = j;
                                index = i;
                            } else if (
                                item2.CHILDREN &&
                                item2.CHILDREN.length >= 0
                            ) {
                                item2.CHILDREN.forEach((item3, k) => {
                                    if (
                                        item3[this.listConfig.value] === value
                                    ) {
                                        index3 = k;
                                        index2 = j;
                                        index = i;
                                    }
                                });
                            }
                        });
                    }
                });
                this.$emit("handleChange", "list", [
                    value,
                    label,
                    index,
                    data.JCSJCOUNT,
                    data,
                    index2,
                    index3,
                ]);
            } else {
                let value = data[this.listConfig.value];
                let label = data[this.listConfig.name];
                let index = -1;
                this.list.forEach((item, i) => {
                    if (item[this.listConfig.value] === value) {
                        index = i;
                    }
                });
                this.$emit("handleChange", "list", [
                    value,
                    label,
                    index,
                    data.JCSJCOUNT,
                    data,
                ]);
            }
        },
        getZT(node, data) {
            if (this.$route.name == "dailyPatrol") {
                let src = "";
                this.legendList.map((item) => {
                    if (
                        item.tclx == data.tclx &&
                        item.ztList.includes(data.zt)
                    ) {
                        src = item.src;
                    }
                });
                return `backgroundImage:url(${src});`;
            } else {
                let dwlx = Number(data.dwlx) === 1 ? "patrol" : "signIn";
                let zt = Number(data.zt) === 1 ? 1 : 0;
                let name = `${dwlx}${zt}`;
                let src = require("@image/map/icon/" + name + ".png");
                if (node.level === 1) return "";
                return `backgroundImage:url(${src});`;
            }
        },
        renderContent(h, { node, data, store }) {
            if (this.currentId === 76) {
                // 日常巡查任务名称要拼接采集人名称，单独处理
                if (data.cjrxm) {
                    return (
                        <span class="custom-tree-node">
                            <span class="node-label">
                                {node.label}-{data.cjrxm}
                            </span>
                            <span
                                class="node-status"
                                style={this.getZT(node, data)}
                            ></span>
                        </span>
                    );
                } else {
                    return (
                        <span class="custom-tree-node">
                            <span class="node-label">{node.label}</span>
                        </span>
                    );
                }
            } else {
                if (data.JCPZCOUNT) {
                    return (
                        <span class="custom-tree-node">
                            <span>{node.label}</span>
                            <span class="count">{data.JCPZCOUNT}</span>
                        </span>
                    );
                } else if (data.JCSJCOUNT === 0) {
                    return (
                        <span class="custom-tree-node disabled">
                            <span>{node.label}</span>
                        </span>
                    );
                } else {
                    return (
                        <span class="custom-tree-node">
                            <span>{node.label}</span>
                        </span>
                    );
                }
            }
        },
        autoResize() {
            $(".task-tree").css("max-height", $(".content").height() - 60);
        },
    },
    destroyed() {
        $(window).unbind("resize");
    },
};
</script>
<style lang="scss" scoped>
.task-page {
    .content {
        width: 100%;
        height: calc(100% - 60px);
        position: relative;
        margin-top: 10px;
        .leftTree {
            width: 280px;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 400;
            .title {
                height: 40px;
                line-height: 40px;
                color: #fff;
                padding-left: 20px;
                text-align-last: left;
                background-color: #336799;
                font-size: 16px;
            }
        }
    }
}
</style>