<template>
    <div class="protection_works">
        <taskCommon
            title="民居考评"
            :currentTree="currentTree"
            :list="list"
            :snSearch="snSearch"
            :listConfig="listConfig"
            :defaultProps="defaultProps"
            widthValue="280px"
            @handleChange="handleChange"
        >
            <slot>
                <Mymap
                    :mapData="mapData"
                    class="map"
                    ref="myMap"
                    @initmap="initMap"
                ></Mymap>
                <Dialog
                    ref="dialog"
                    :dialogData.sync="dialogData"
                    :MC="MC"
                    @closeDisesaseDialog="closeDisesaseDialog"
                    @saveSuccess="saveSuccess"
                >
                </Dialog>
            </slot>
        </taskCommon>
    </div>
</template>
<script>
import taskCommon from "../task-common";
import mixins from "../mixins";
import Dialog from "./residential_assess_dialog";
import { mapActions } from "vuex";
export default {
    name: "residential_assess",
    components: {
        taskCommon,
        Dialog,
    },
    mixins: [mixins],
    data() {
        return {
            mapData: {
                dxtState: true, //地形图
                maxZoom: 20,
                zoom: 12.5,
                areaState: true,
            },
            currentId: 30801,
            list: [],
            listConfig: {
                name: "XZZ",
                value: "XZZ",
            },
            basicMap: null,
            MC: "",
            selectedId: "",
            selectedIndex: 0,
            areaLayers: null,
            defaultProps: {
                children: "child",
                label: "MC",
            },
        };
    },
    mounted() {
        this.dialogData.title = "民居考评配置";
    },
    methods: {
        ...mapActions(["getMJKPDataList"]),
        handleClose() {},
        // 获得民族文化列表
        async getCommuterList() {
            let res = await this.getMJKPDataList();
            this.list = res;
        },
        async changeList(val) {
            // 点击景点列表，直接显示弹出框
            this.currentTree = val[0];
            this.MC = val[1];
            this.selectedIndex = val[2];
            this.getJcDetail(val);
        },
        // 从列表视图点击 获得配置详情
        getJcDetail(item) {
            this.selectedId = item[0];
            this.dialogData.title = `${this.MC}-监测任务配置`;
            this.dialogData.dialog = true;
            this.$refs.dialog.getDetail(item[0]);
        },
        // 关闭弹框时 清除选中
        closeDisesaseDialog() {
            this.selectedId = "";
        },
        async initMap(map) {
            this.basicMap = map;
            await this.getCommuterList();
        },
        // 保存成功后 重新获得列表
        async saveSuccess() {
            await this.getCommuterList();
        },
    },
};
</script>
<style lang="scss" scoped>
</style>