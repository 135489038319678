import taskCommon from './task-common';
import { mapGetters } from "vuex";
export default {
    components: {
        taskCommon
    },
    props: {},
    data() {
        return {
            // 地图
            taskMap: null,
            selectID: '', // 从任务管理页面点击地图按钮选中的id
            // 缓冲区
            // bounds: [
            //     [42.1697, 116.5561],
            //     [42.6628, 115.902]
            // ],
            // 片区
            // bounds: [
            //     [42.4655, 116.0526],
            //     [42.273, 116.333]
            // ],
            // 城区
            bounds: [
                [42.4082, 116.1114],
                [42.3353, 116.2267]
            ],
            currentTree: '', // 左侧树当期选中项
            currentId: 76, // 当前选中监测类型
            keyword: '', // 关键字搜索
            dialogData: {
                title: '',
                dialog: false,
                class: 'deepBlueDialog'
            },
            showNewButton: false,
            snSearch: {
                inputData: [{
                    label: '关键字',
                    placeholder: "关键字",
                    value: 'keyword',
                    operateType: 'search',
                    isShow: true
                }],
                selectData: [{
                    clearable: false,
                    class: 'selectRound',
                    selectValue: 76,
                    list: [],
                    optionLabel: 'name',
                    optionValue: 'value',
                    width: '200px',
                    value: 'type',
                    operateType: 'search',
                    isShow: true
                }],
                searchBtn: {
                    buttonData: [{
                        btnType: 'button',
                        operateType: 'buttonToList',
                        name: '返回任务管理',
                        round: true,
                        backColor: '#11cf65',
                        color: '#fff'
                    }]
                }
            },
            list: [],
            currentItem: null
        };
    },
    computed: {
        ...mapGetters(["system"]),
        initOver() {
            let routeType = this.$route.params.type;
            let params = [this.list && this.list.length > 0, this.basicMap !== null, routeType && routeType === 'highLightCurrent'];
            return params.join('-');
        }
    },
    watch: {
        initOver() {
            this.handleInitOver();
        },
    },
    created() {},
    methods: {
        // 点击地图跳转时，此方法控制弹出框是否弹出
        handleInitOver() {
            if (this.initOver === 'true-true-true') {
                let value = this.listConfig ? this.listConfig.value : 'value';
                let label = this.listConfig ? this.listConfig.name : 'label';
                let current = null
                if (this.$route.name == "heritageMonomer") {
                    if (this.list && this.list.length > 0) {
                        this.currentItem = null;
                        this.handleList111(this.list, "ID");
                        current = this.currentItem;
                        if (!current) return;
                        this.handleChange('list', [current[value], current[label], current, null, { WD: current.WD, JD: current.JD }]);
                    }
                } else {
                    if (this.$route.name == "dailyPatrol" || this.$route.name == "ancientTeaTree") {
                        this.list.filter(item => {
                            let child = 'child';
                            if (this.$route.name == "ancientTeaTree") {
                                child = "trees";
                            }
                            item[child].filter(item1 => {
                                if (item1[value] && (item1[value] === this.$route.params.value || item1[value] === this.$route.params.data.jcdxid)) {
                                    current = item1
                                }
                            });
                        });
                        if (!current) return;
                        this.handleChange('list', [current[value], current[label], current, null, current]);
                    } else {
                        this.list.filter(item => {
                            if (item[value] && (item[value] === this.$route.params.value || item[value] === this.$route.params.data.jcdxid)) {
                                current = item
                            }
                        });
                        if (!current) return;
                        this.handleChange('list', [current[value], current[label]]);
                    }
                }
            }
        },
        handleList111(list, value) {
            for (let i = 0; i < list.length; i++) {
                if (list[i][value] && (list[i][value] === this.$route.params.value || list[i][value] === this.$route.params.data.jcdxid)) {
                    this.currentItem = list[i];
                    break;
                } else if (list[i].CHILDREN && list[i].CHILDREN.length > 0) {
                    this.handleList111(list[i].CHILDREN, value);
                }
            }
        },
        setCommonData() {
            if (this.showNewButton) { // 旅游游客和日常巡查有新建按钮
                let buttonData = this.snSearch.searchBtn.buttonData;
                let newButton = {
                    btnType: 'button',
                    operateType: 'buttonNew',
                    name: this.newButtonName ? this.newButtonName : '新建',
                    round: true,
                    backColor: '#28ccd9',
                    color: '#fff'
                };
                // let newButton1 = {
                //     btnType: 'button',
                //     operateType: 'buttonNewArea',
                //     name: '新建巡查区(临时)',
                //     round: true,
                //     backColor: '#28ccd9',
                //     color: '#fff'
                // };
                buttonData.unshift(newButton);
                // buttonData.unshift(newButton1);
            }
        },
        getJcxList() {
            let list = localStorage.jcxList;
            if (list) {
                let basicData = JSON.parse(localStorage.jcxList);
                if (this.system == "system2") {
                    let list = []
                    basicData.map((item) => {
                        if (item.value == "1503") {
                            item.label = "林业项目"
                            item.name = "林业项目"
                            list.push(item)
                        } else if (item.value == "76") {
                            list.push(item)
                        }
                    })
                    basicData = Array.from(list)
                }
                basicData = basicData.filter(item => {
                    return item.name !== '异常预警跟踪';
                });
                this.monitorOptions = basicData;
                let selectData = this.snSearch.selectData[0];
                selectData.list = basicData; // 监测项切换下拉菜单
            } else {
                setTimeout(() => {
                    this.getJcxList();
                }, 10);
            }
        },
        initMap(map) {
            this.taskMap = map;
        },
        handleChange(type, value) {
            switch (type) {
                case 'search':
                    this.toOtherPage(value);
                    break;
                case 'list':
                    this.changeList(value);
                    break;
                case 'buttonNew':
                    this.creatMonitor();
                    break;
                case 'buttonNewArea':
                    this.creatArea();
                    break;
                case 'buttonToList':
                    this.$router.push({
                        name: 'taskManage'
                    });
                    break;
                default:
                    this.handleSelfChange(type, value);
                    break;
            }
            console.log(type, value);
        },

        //* ****** changelist写到业务组件中
        // changeList(val) {
        // this.currentTree = val[0];
        // },
        toOtherPage(val) {
            this[val[1]] = val[0];
            if(val && val[1] == 'keyword') {
                this.keyword = val[0];
            }
            this.snSearch.selectData[0].list.forEach(item => {
                if (item.value === val[0]) {
                    this.$router.push({
                        name: item.path
                    });
                }
            });
        },
    },
    mounted() {
        this.snSearch.selectData[0].selectValue = this.currentId;
        this.currentTree = this.$route.params.value;
        this.setCommonData();
        this.getJcxList();
    }
};